.trip-container .rdt_TableHeadRow {
  border-top: none;
  border-bottom: none;
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.trip-container {
  max-width: 100%;
}
.transport-card {
  background: #fff;
  border-radius: 12px;
  padding: 20px;
  border: 1px solid #ddd;
  /* width: 500px; */
}

.location {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 14px;
}
.location p {
  margin-bottom: 0px;
}

.icon {
  color: #007bff;
  font-size: 20px;
}

.info {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-between;
  font-size: 12px;
}
.info p {
  margin-bottom: 0px;
}

.tabs-container {
  position: relative;
  width: 90%;
  z-index: 1000;
  padding-left: 25px;
}

.tabs {
  display: flex;
  align-items: center;
  gap: 20px;
  font-weight: 500;
  font-size: 20px;
  color: #888;
}

.tab-item {
  cursor: pointer;
  padding-bottom: 5px;
  transition: color 0.2s;
}

.tab-item:hover {
  color: #333;
}

.active1 {
  color: #2563eb;
  border-bottom: 2px solid #2563eb;
}

.divider {
  width: 100%;
  border: none;
  height: 2px;
  color: #000000;
  margin-top: -1px;
  margin-bottom: 10px;
  opacity: 1;
}

.relative > input {
  outline: none;
}

/* @media (max-width: 576px) {
    .info {
      flex-direction: column;
    }
  } */
